<template>
  <div class="flex w-full">
    <div
      class="relative grid flex-grow grid-cols-1 items-start justify-center gap-x-4 md:grid-cols-[1fr,auto,1fr]"
    >
      <slot name="sidePanel">
        <div class="hidden md:block" />
      </slot>
      <flux-form
        class="flex max-w-xl flex-col justify-center space-y-4 justify-self-center"
        ref="form"
        :errors="errors"
      >
        <span class="ml-2 text-3xl">
          {{ $t("patient.create.form.title") }}
        </span>

        <div
          id="personal_info"
          :ref="
            (el) => {
              registerRef('stage_personal_info', el);
            }
          "
        >
          <PatientCreatePersonalInfo
            v-model:modelValue="patientCreateForm"
            v-model:bsnAlreadyExists="bsnAlreadyExists"
            :covLoading="identityLoading"
            @cov="performCovCheck()"
            @sbvz="performSbvzCheck()"
            @error="setStageError('personal_info', $event)"
          />
        </div>
        <div
          id="identity_documents"
          :ref="
            (el) => {
              registerRef('stage_identity_documents', el);
            }
          "
        >
          <PatientCreateIdentityDocuments
            v-model:modelValue="patientCreateForm"
            :covLoading="identityLoading"
            @error="setStageError('identity_documents', $event)"
          />
        </div>
        <div
          id="name"
          :ref="
            (el) => {
              registerRef('stage_name', el);
            }
          "
        >
          <PatientCreateName
            v-model:modelValue="patientCreateForm"
            :errors="errors"
            @error="setStageError('name', $event)"
          />
        </div>
        <div
          id="contact_info"
          :ref="
            (el) => {
              registerRef('stage_contact_info', el);
            }
          "
        >
          <PatientCreateContactInfo
            v-model:modelValue="patientCreateForm"
            @error="setStageError('contact_info', $event)"
          />
        </div>
        <div
          id="addresses"
          :ref="
            (el) => {
              registerRef('stage_addresses', el);
            }
          "
        >
          <PatientCreateAddress
            v-model:modelValue="patientCreateForm.addresses"
            :errors="errors"
            @error="setStageError('addresses', $event)"
          />
        </div>
        <div
          id="payer_insurers"
          :ref="
            (el) => {
              registerRef('stage_payer_insurers', el);
            }
          "
        >
          <PatientCreatePayerInsurers
            v-model:modelValue="patientCreateForm.payer_insurers"
            @error="(v) => setStageError('payer_insurers', v)"
          />
          <PatientCreateTreatmentCount
            :patientTreatmentCount="patientCreateForm.patient_treatment_count"
          />
        </div>
        <div
          id="company"
          v-if="showCompany"
          :ref="
            (el) => {
              registerRef('stage_company', el);
            }
          "
        >
          <PatientCreateCompany
            v-model:modelValue="patientCreateForm"
            @error="setStageError('company', $event)"
          />
        </div>
        <div
          id="contacts"
          :ref="
            (el) => {
              registerRef('stage_contacts', el);
            }
          "
        >
          <PatientCreateContacts
            v-model:modelValue="patientCreateForm.contacts"
            @error="(v) => setStageError('contacts', v)"
          />
        </div>
        <div
          id="healthcare_professionals"
          :ref="
            (el) => {
              registerRef('stage_healthcare_professionals', el);
            }
          "
        >
          <PatientCreateHealthcareProfessionals
            v-model:modelValue="patientCreateForm.healthcare_professionals"
            @error="(v) => setStageError('healthcare_professionals', v)"
          />
        </div>
        <flux-button-group class="mx-4 !mb-4 justify-end">
          <flux-button
            type="secondary"
            @click="clearForm()"
            :disabled="isSaving"
          >
            {{ $t("patient.create.form.reset") }}
          </flux-button>
          <flux-tooltip
            :disabled="!bsnAlreadyExists"
            content="Patiënt is al geregistreerd"
          >
            <flux-submit-button
              @click="save()"
              :disabled="isSaving || bsnAlreadyExists"
            >
              {{ $t("patient.create.form.save") }}
            </flux-submit-button>
          </flux-tooltip>
        </flux-button-group>
      </flux-form>
      <div class="sticky top-24 hidden md:flex">
        <div
          class="z-0 translate-x-[2px] transform border-l-2 border-border-primary"
        />
        <div class="patient-create-menu z-10 p-0">
          <div
            v-for="(stage, index) in stages"
            :class="[
              'flex cursor-pointer border-l-2 py-3 pl-4 hover:text-text-primaryHover',
              stage.active
                ? 'border-blue-500 text-blue-400'
                : 'border-transparent',
            ]"
            :key="'stageMenu' + index.toString()"
            @click="
              stageRefs.get(`stage_${stage.prop}`)?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              })
            "
          >
            {{ $t("patient.create.form.step." + stage.prop + ".title_menu") }}
            <div class="w-6 pl-1">
              <template v-if="stage.error">
                <i class="fas fa-times-circle text-red-500" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import {
  ComponentPublicInstance,
  computed,
  onBeforeUpdate,
  onMounted,
  ref,
} from "vue";
import moment from "moment";
import { PatientCreateForm } from "@/models/Forms";
import {
  PayerInsurerBody,
  PayerInsurerForm,
  payerInsurerFormToApi,
} from "../../models/PayerInsurer";
import PatientCreateAddress from "./PatientCreateAddress.vue";
import PatientCreateCompany from "./PatientCreateCompany.vue";
import PatientCreateName from "./PatientCreateName.vue";
import PatientCreateIdentityDocuments from "./PatientCreateIdentityDocuments.vue";
import PatientCreateHealthcareProfessionals from "./PatientCreateHealthcareProfessionals.vue";
import PatientCreateContacts from "./PatientCreateContacts.vue";
import PatientCreateContactInfo from "./PatientCreateContactInfo.vue";
import PatientCreatePayerInsurers from "./PatientCreatePayerInsurers.vue";
import PatientCreateTreatmentCount from "./PatientCreateTreatmentCount.vue";
import PatientCreatePersonalInfo from "./PatientCreatePersonalInfo.vue";
import { FluxApi } from "../../apis/flux";
import { useNotify } from "@/composables/notify";
import {
  covCheck,
  CovCheckResponse,
  notifyCovCheckError,
} from "@/actions/covCheck";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { genderTypes } from "@/models/Patient";
import { $t } from "@/libraries/i18n";
import store from "@/libraries/store";
import { onBeforeRouteLeave } from "vue-router";
import { useRouter } from "vue-router";
import { usePatientTitles } from "@/composables/patientTitle";
import { useConfirm } from "@/composables/confirm";
import { clone } from "@/libraries/utils/clone";
import { useFormAutoSave } from "@/composables/formAutoSaveManager";
import { handleErrors } from "@/libraries/utils/errorHandling";
import { useSbvzSearch } from "@/composables/sbvzSearch";
import { bsnIsValid } from "@/libraries/utils/bsn";

const { notify } = useNotify();

const router = useRouter();

const stageProps = [
  "personal_info",
  "identity_documents",
  "name",
  "addresses",
  "contact_info",
  "payer_insurers",
  "company",
  "contacts",
  "healthcare_professionals",
] as const;

function isStageProp(s: unknown): s is StageProp {
  return typeof s === "string" && (stageProps as readonly string[]).includes(s);
}

type StageProp = (typeof stageProps)[number];

interface Stage {
  prop: StageProp;
  active: boolean;
  error: boolean;
}

const identityLoading = ref(false);

const isSaving = ref(false);

const errors = ref<MessageBag>(new MessageBag({}));

const stages = ref<Stage[]>([]);

const addressTypeOptions = ref<{ value: string; label: string }[]>([]);

const start = ref<Date>();

const showCompany = computed(() => hasFeatureFlag("companies"));

const { data: patientTitles } = usePatientTitles();
const defaultPatientTitle = patientTitles.value?.filter(
  (x) => x.language === "nl-NL" && x.default_for_gender === "UNK",
)[0];

const { executeSbvzSearch } = useSbvzSearch();

const initialform: PatientCreateForm = {
  first_names: "",
  nickname: "",
  surname: "",
  surname_prefix: "",
  maiden_name: "",
  maiden_name_prefix: "",
  gender: undefined,
  patient_title_uuid: defaultPatientTitle?.uuid ?? null,
  custom_short_title: null,
  custom_long_title: null,
  salutation_template_uuid: null,
  custom_salutation: null,
  email_addresses: [
    {
      email_address: "",
      use_for_appointment: true,
      use_for_financial: true,
      use_for_medical: true,
    },
  ],
  phone_numbers: [
    {
      phone_number: "",
      phone_number_type: "MC",
      preferred: true,
    },
  ],
  date_of_birth: "",
  bsn: "",
  national_identity_number_type: "bsn",
  nationality_id: undefined,
  multiple_birth: false,
  initials: "",
  identity_documents: [
    {
      type: "id_card",
      number: "",
    },
  ],
  contacts: [],
  payer_insurers: [
    {
      number: "",
      type: "B",
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().endOf("year").format("YYYY-MM-DD"),
      insurer_id: undefined,
      insurer_name: "",
      insurer_number: "",
      package_name: "",
      package_code: undefined,
    },
  ],
  addresses: [
    {
      address_type: "PHYS",
      street_name: "",
      house_number: "",
      postal_code: "",
      city: "",
      country_id: 159,
    },
  ],
  healthcare_professionals: [],
  languages: ["nl"],
  company_division_id: undefined,
  patient_treatment_count: {
    amount: "0",
    reference_year: new Date().getFullYear().toString(),
  },
};

const patientCreateForm = ref<PatientCreateForm>(clone(initialform));
const bsnAlreadyExists = ref<boolean>(false);

const emptyPatientCreateForm = ref<PatientCreateForm>(
  clone(patientCreateForm.value),
);

const stageRefs = ref<Map<`stage_${StageProp}`, HTMLDivElement>>(new Map());

const { loadedData: savedFormData, removeData: removeFormData } =
  useFormAutoSave(
    ref<string>("patientcreate"),
    ref<string>("/patients/create"),
    patientCreateForm,
  );

function registerRef(
  refName: `stage_${StageProp}`,
  el: Element | ComponentPublicInstance | null,
) {
  if (!el) {
    return;
  }
  stageRefs.value.set(refName, el as HTMLDivElement);
}

onBeforeUpdate(() => {
  stageRefs.value.clear();
});

onMounted(async () => {
  let savedData = await savedFormData;

  if (savedData !== undefined) {
    patientCreateForm.value = savedData;
    if (patientCreateForm.value.phone_numbers.length === 0) {
      patientCreateForm.value.phone_numbers = initialform.phone_numbers;
    }
    if (patientCreateForm.value.email_addresses.length === 0) {
      patientCreateForm.value.email_addresses = initialform.email_addresses;
    }
    if (patientCreateForm.value.addresses.length === 0) {
      patientCreateForm.value.addresses = initialform.addresses;
    }
    if (patientCreateForm.value.payer_insurers.length === 0) {
      patientCreateForm.value.payer_insurers = initialform.payer_insurers;
    }
  }

  start.value = new Date();

  const toOptions = (translation: string, list: string[]) =>
    list.map((value) => {
      return {
        value,
        label: $t(translation + "." + value),
      };
    });

  addressTypeOptions.value = toOptions("labels.address_type", [
    "HP",
    "PST",
    "PHYS",
    "TMP",
    "WP",
    "HV",
  ]);

  setStages();
  observeStages();
});

function clearForm() {
  patientCreateForm.value = clone(initialform);
}

function setStages() {
  registerStage("personal_info");
  registerStage("identity_documents");
  registerStage("name");
  registerStage("contact_info");
  registerStage("addresses");
  registerStage("payer_insurers");
  if (showCompany.value) {
    registerStage("company");
  }
  registerStage("contacts");
  registerStage("healthcare_professionals");
}

function registerStage(prop: StageProp) {
  stages.value.push({
    prop,
    active: false,
    error: false,
  });
}

function observeStages() {
  const handler = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (isStageProp(entry.target.id)) {
        setStageActive(entry.target.id, entry.intersectionRatio > 0.5);
      } else {
        throw new Error(
          "Got unexpected id " +
            entry.target.id +
            ", expected typeof StageProp",
        );
      }
    });
  };

  const observer = new IntersectionObserver(handler, {
    threshold: 0.5,
  });

  stageProps.forEach((prop) => {
    const element = stageRefs.value.get(`stage_${prop}`);
    if (element) {
      observer.observe(element);
    }
  });
}

function setStageActive(stageProp: StageProp, active: boolean) {
  const number = stages.value.findIndex((stage) => stage.prop === stageProp);
  stages.value[number].active = active;
}

function setStageError(stageProp: StageProp, error: boolean) {
  const number = stages.value.findIndex((stage) => stage.prop === stageProp);
  stages.value[number].error = error;
}

async function performCovCheck() {
  identityLoading.value = true;
  try {
    const { data } = await covCheck({
      date_of_birth: patientCreateForm.value.date_of_birth,
      bsn: patientCreateForm.value.bsn,
      postal_code: patientCreateForm.value.addresses[0].postal_code,
      house_number: patientCreateForm.value.addresses[0].house_number,
    });

    if (data.payer_insurers.length === 0 || data.patient === undefined) {
      notifyCovCheckError(data);
      return;
    }

    fillEmptyPatientFields(data.patient);
    notify({
      message: data.result,
      type: "success",
    });
    removeEmptyInsurance();
    data.payer_insurers.forEach((payer_insurer) => {
      addOrChangeInsurance(
        payer_insurer,
        patientCreateForm.value.payer_insurers,
      );
    });
  } catch {
    notifyCovCheckError();
  } finally {
    identityLoading.value = false;
  }
}

async function performSbvzCheck() {
  identityLoading.value = true;
  const res = await executeSbvzSearch(patientCreateForm.value.bsn);
  if (!res) {
    identityLoading.value = false;
    return;
  }
  patientCreateForm.value.first_names = res.Voornamen;
  patientCreateForm.value.date_of_birth = res.Geboortedatum;
  patientCreateForm.value.surname = res.Geslachtsnaam;
  patientCreateForm.value.surname_prefix = res.VoorvoegselGeslachtsnaam;
  if (genderTypes.includes(res.Geslachtsaanduiding)) {
    patientCreateForm.value.gender = res.Geslachtsaanduiding;
  } else {
    notify({
      message: `Kan geslacht uit SBV-Z BSN-check niet koppelen. SBV-Z-data: ${res.Geslachtsaanduiding}`,
      type: "error",
      duration: 12000,
    });
  }
  let houseNumber = res.Huisnummer.toString();

  houseNumber = [houseNumber, res.Huisletter, res.Huisnummertoevoeging]
    .filter((x) => x)
    .join(" ");
  const address = {
    id: 1,
    postal_code: res.Postcode,
    city: res.Woonplaatsnaam,
    house_number: houseNumber,
    street_name: res.Straatnaam,
    address_type: "HP" as const,
    country_id: 159,
  };
  patientCreateForm.value.addresses.push(address);
  patientCreateForm.value.addresses =
    patientCreateForm.value.addresses.reverse();
  identityLoading.value = false;

  if (
    bsnIsValid(patientCreateForm.value.bsn).success &&
    patientCreateForm.value.date_of_birth &&
    filterEmptyPayerInsurers(patientCreateForm.value.payer_insurers).length ===
      0
  ) {
    performCovCheck();
  }
}

function filterEmptyPayerInsurers(payerInsurers: PayerInsurerForm[]) {
  return payerInsurers.filter(
    (payer_insurer) =>
      payer_insurer.number != "" ||
      payer_insurer.insurer_number != "" ||
      payer_insurer.type != "B",
  );
}

function removeEmptyInsurance() {
  patientCreateForm.value.payer_insurers = filterEmptyPayerInsurers(
    patientCreateForm.value.payer_insurers,
  );
}

function addOrChangeInsurance(
  newPayerInsurer: CovCheckResponse["payer_insurers"][number],
  payer_insurers: PayerInsurerForm[],
) {
  const newForm: PayerInsurerForm = {
    number: newPayerInsurer.number,
    insurer_number: newPayerInsurer.insurer_number,
    start_date: newPayerInsurer.start_date.toString(),
    end_date: newPayerInsurer.end_date.toString(),
    type: newPayerInsurer.type,
    insurer_id: undefined,
    insurer_name: newPayerInsurer.insurer_name,
    package_name: newPayerInsurer.package_name,
    package_code: newPayerInsurer.package_code,
  };

  for (let i = 0; i < payer_insurers.length; i++) {
    if (
      newForm.number == payer_insurers[i].number &&
      newForm.insurer_number == payer_insurers[i].insurer_number &&
      newForm.start_date == payer_insurers[i].start_date &&
      newForm.type == payer_insurers[i].type
    ) {
      patientCreateForm.value.payer_insurers[i] = newForm;
      return;
    }
  }
  patientCreateForm.value.payer_insurers.push(newForm);
}

function capitalizeName(name: string) {
  return name.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

function fillEmptyPatientFields(
  patient: NonNullable<CovCheckResponse["patient"]>,
) {
  if (patientCreateForm.value.surname == "") {
    patientCreateForm.value.surname = capitalizeName(patient.surname);
    patientCreateForm.value.surname_prefix =
      patient.surname_prefix?.toLowerCase() ?? "";
  } else if (patientCreateForm.value.surname != patient.surname) {
    createNameErrorMsg(
      patientCreateForm.value.surname,
      patient.surname,
      "surname",
      patientCreateForm.value.surname_prefix,
      patient.surname_prefix,
    );
  }

  if (
    patientCreateForm.value.maiden_name === undefined ||
    patientCreateForm.value.maiden_name === ""
  ) {
    patientCreateForm.value.maiden_name = capitalizeName(
      patient.maiden_name ?? "",
    );
    patientCreateForm.value.maiden_name_prefix =
      patient.maiden_name_prefix?.toLocaleLowerCase() ?? "";
  } else if (patientCreateForm.value.maiden_name !== patient.maiden_name) {
    createNameErrorMsg(
      patientCreateForm.value.maiden_name,
      patient.maiden_name ?? "",
      "maiden_name",
      patientCreateForm.value.maiden_name_prefix,
      patient.maiden_name_prefix,
    );
  }
  if (!patientCreateForm.value.bsn && patient.bsn) {
    patientCreateForm.value.bsn = patient.bsn;
  }

  patientCreateForm.value.gender = patient.gender;
  patientCreateForm.value.patient_title_uuid =
    patientTitles.value?.filter(
      (x) => x.language === "nl-NL" && x.default_for_gender === patient.gender,
    )[0].uuid ?? null;
}

function createNameErrorMsg(
  formName: string,
  dataName: string,
  nameType: string,
  formName_prefix?: string,
  dataName_prefix?: string,
) {
  let formNamePrefix = formName_prefix == null ? "" : formName_prefix + " ";
  let dataNamePrefix = dataName_prefix == null ? "" : dataName_prefix + " ";
  notify({
    message: $t("patient.cov_check.error.wrong_" + nameType, [
      formNamePrefix + formName,
      dataNamePrefix + dataName,
    ]) as string,
    type: "error",
    duration: 12000,
  });
}

const { confirm } = useConfirm();

async function save() {
  isSaving.value = true;
  const body = formToApiBody(patientCreateForm.value);
  if (
    body.addresses?.length == 0 ||
    body.phone_numbers?.length == 0 ||
    body.email_addresses?.length == 0
  ) {
    const missingFields = [];
    if (body.addresses?.length == 0) {
      missingFields.push("addres");
    }
    if (body.phone_numbers?.length == 0) {
      missingFields.push("telefoonnummer");
    }
    if (body.email_addresses?.length == 0) {
      missingFields.push("email");
    }
    if (
      !(await confirm({
        message: `Wilt u deze patient opslaan zonder: ${missingFields.join(
          ", ",
        )}`,
        type: "default",
        confirmButtonText: "Opslaan",
        cancelButtonText: "Terug",
        canCancel: true,
      }))
    ) {
      isSaving.value = false;
      return null;
    }
  }

  apiClient
    .post("/patients", body)
    .then((response) => {
      notify({
        message: $t("patient.create.result.success") as string,
        type: "success",
      });
      if (start.value && import.meta.env.VITE_SENTRY_RELEASE !== undefined) {
        const time = new Date().getTime() - start.value.getTime();
        apiClient.post("/timings", {
          feature: "patient.create",
          build: import.meta.env.VITE_SENTRY_RELEASE,
          time,
          metadata: {
            addresses: (body.addresses || []).length,
            email_addresses: (body.email_addresses || []).length,
            phone_numbers: (body.phone_numbers || []).length,
            contacts: (body.contacts || []).length,
            healthcare_professionals: (body.healthcare_professionals || [])
              .length,
            payer_insurers: (body.payer_insurers || []).length,
            identity_documents: (body.identity_documents || []).length,
            nationalities: (body.nationalities || []).length,
          },
        });
      }
      const patient = response.data.patient;
      if (router.currentRoute.value.query.from === "calendar") {
        store.commit("calendar/selectedPatientZisNumber", patient.zis_number);
        store.commit("calendar/addPatient", patient);
        router.push("/calendar");
      } else {
        router.push("/patient/" + patient.zis_number);
      }
      removeFormData();
    })
    .catch((error) => {
      handleErrors({ error });
    })
    .finally(() => {
      isSaving.value = false;
    });
}

/**
 * Filters empty fields and formats dates from MM-DD-YYYY to YYYY-MM-DD
 */
function formToApiBody(
  initialForm: PatientCreateForm,
): FluxApi["/patients"]["POST"]["body"] {
  const apiForm: PatientCreateForm = clone(initialForm);
  // Remove empty identity_documents
  const identityDocuments = apiForm.identity_documents.filter(
    (identityDocument) => !!identityDocument.number,
  );

  // Remove empty contacts and empty contact info of those contacts
  const contactsWithFullname = apiForm.contacts.filter(
    (contact) => !!contact.fullname,
  );
  const contacts = contactsWithFullname.map((contact) => {
    return {
      ...contact,
      email_addresses: contact.email_addresses.filter(
        (emailAddress) => emailAddress.email_address !== "",
      ),
      phone_numbers: contact.phone_numbers.filter(
        (phoneNumber) => phoneNumber.phone_number !== "",
      ),
    };
  });

  // Remove empty contact info
  const emailAddresses = apiForm.email_addresses.filter(
    (emailAddress) => !!emailAddress.email_address,
  );

  const phoneNumbers = apiForm.phone_numbers.filter(
    (phoneNumber) => !!phoneNumber.phone_number,
  );

  const payerInsurers = apiForm.payer_insurers.filter(
    (payerInsurer) => !!payerInsurer.number,
  );
  const treatmentCounts = [
    {
      amount: Number(patientCreateForm.value.patient_treatment_count.amount),
      reference_year: Number(
        patientCreateForm.value.patient_treatment_count.reference_year,
      ),
      source: "manual",
    },
  ];
  const payerInsurersBody = payerInsurerFormArrayToBody(payerInsurers);
  const addresses = apiForm.addresses.filter(
    (address) => !!address.city || !!address.postal_code,
  );
  const healthcareProfessionals = apiForm.healthcare_professionals.filter(
    (hcProf) => !!hcProf.fullname,
  );

  const nationalities = [];
  // Add nationality
  if (patientCreateForm.value.nationality_id) {
    nationalities.push(patientCreateForm.value.nationality_id);
  }

  let nationalIdentityNumberType = undefined;
  if (patientCreateForm.value.bsn !== "") {
    nationalIdentityNumberType =
      patientCreateForm.value.national_identity_number_type;
  }

  return {
    first_names: patientCreateForm.value.first_names,
    nickname: patientCreateForm.value.nickname,
    surname: patientCreateForm.value.surname,
    surname_prefix: patientCreateForm.value.surname_prefix,
    maiden_name: patientCreateForm.value.maiden_name,
    maiden_name_prefix: patientCreateForm.value.maiden_name_prefix,
    gender: patientCreateForm.value.gender,
    patient_title_uuid:
      patientCreateForm.value.patient_title_uuid ??
      defaultPatientTitle?.uuid ??
      null,
    custom_short_title: patientCreateForm.value.custom_short_title,
    custom_long_title: patientCreateForm.value.custom_long_title,
    salutation_template_uuid: patientCreateForm.value.salutation_template_uuid,
    custom_salutation: patientCreateForm.value.custom_salutation,
    date_of_birth: patientCreateForm.value.date_of_birth,
    bsn: patientCreateForm.value.bsn,
    national_identity_number_type: nationalIdentityNumberType,
    multiple_birth: patientCreateForm.value.multiple_birth,
    initials: patientCreateForm.value.initials,
    languages: patientCreateForm.value.languages,

    email_addresses: emailAddresses,
    phone_numbers: phoneNumbers,
    payer_insurers: payerInsurersBody,
    patient_treatment_counts: treatmentCounts,
    identity_documents: identityDocuments,
    healthcare_professionals: healthcareProfessionals,

    company_division_id: patientCreateForm.value.company_division_id,
    employee_number: patientCreateForm.value.employee_number,

    contacts,
    addresses,
    nationalities,
  };
}

function payerInsurerFormArrayToBody(
  payerInsurers: PayerInsurerForm[],
): PayerInsurerBody[] {
  let payerInsurersBody: PayerInsurerBody[] = [];
  payerInsurers.forEach((payerInsurer) => {
    payerInsurersBody.push(payerInsurerFormToApi(payerInsurer));
  });
  return payerInsurersBody;
}

function formIsEmpty(): boolean {
  return (
    inputIsEmpty("bsn") &&
    inputIsEmpty("surname") &&
    inputIsEmpty("nickname") &&
    inputIsEmpty("gender") &&
    inputIsEmpty("initials") &&
    inputIsEmpty("date_of_birth")
  );
}

function inputIsEmpty(key: keyof PatientCreateForm) {
  let empty = emptyPatientCreateForm.value;
  let full = patientCreateForm.value;
  return empty[key] === full[key];
}

onBeforeRouteLeave(async (to, from, next) => {
  if (isSaving.value) {
    return next();
  }

  if (!formIsEmpty()) {
    const shouldLeave = await confirm({
      message: $t("patient.create.confirm_exit") as string,
    });
    if (shouldLeave) {
      return next();
    } else {
      return next(false);
    }
  } else {
    return next();
  }
});
</script>
