<template>
  <div class="contents">
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.create.form.step.contact_info.title')"
    />
    <flux-card shadow="never">
      <flux-short-form>
        <div
          class="contents"
          v-for="(phone_number, index) in patientCreateForm.phone_numbers"
          :key="'phone' + index.toString()"
        >
          <flux-short-form-item
            :label="$t('patient.create.form.step.phone_number.phone_number')"
            span="col-span-10 md:col-span-6"
          >
            <flux-input
              v-model:modelValue="
                patientCreateForm.phone_numbers[index].phone_number
              "
              type="tel"
              :prop="
                'telephone_numbers.' + index.toString() + '.telephone_number'
              "
              @error="emit('error', $event)"
            />
          </flux-short-form-item>
          <flux-short-form-item span="col-span-2 md:hidden">
            <flux-button
              type="text"
              size="small"
              icon="fal fa-times"
              @click="removePhoneNumber(index)"
            />
          </flux-short-form-item>
          <flux-short-form-item
            :label="
              $t('patient.create.form.step.phone_number.phone_number_type')
            "
            span="col-span-6 md:col-span-5"
          >
            <flux-select
              v-model:modelValue="
                patientCreateForm.phone_numbers[index].phone_number_type
              "
            >
              <option
                v-for="(phoneNumberType, index2) in phoneNumberTypes"
                :key="'phonet' + index2.toString()"
                :value="phoneNumberType"
                :label="$t(`phone_number.type.${phoneNumberType}`)"
              >
                {{ $t(`phone_number.type.${phoneNumberType}`) }}
              </option>
            </flux-select>
          </flux-short-form-item>
          <flux-short-form-item span="col-span-1 hidden md:block">
            <flux-button
              type="text"
              size="small"
              icon="fal fa-times"
              @click="removePhoneNumber(index)"
            />
          </flux-short-form-item>
        </div>
        <div class="col-span-12 -mt-4">
          <flux-button
            type="text"
            size="small"
            icon="fal fa-plus"
            @click="addPhoneNumber"
          >
            {{ $t("patient.create.form.step.phone_number.add_more") }}
          </flux-button>
        </div>
        <PatientEmailAddressForm
          v-for="(email_address, index3) in patientCreateForm.email_addresses"
          v-model:emailAddress="patientCreateForm.email_addresses[index3]"
          :key="'email' + index3.toString()"
          @remove="removeEmailAddress(index3)"
        />
        <div class="col-span-12 -mt-4">
          <flux-button
            type="text"
            size="small"
            icon="fal fa-plus"
            @click="addEmailAddress"
          >
            {{ $t("patient.create.form.step.email_address.add_more") }}
          </flux-button>
        </div>
      </flux-short-form>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { phoneNumberTypes } from "../../models/PhoneNumber";
import { PatientCreateForm } from "@/models/Forms";
import EmailAddressForm from "../EmailAddressForm.vue";
import PatientEmailAddressForm from "../PatientEmailAddressForm.vue";

const patientCreateForm = defineModel<PatientCreateForm>({
  required: true,
});

const emit = defineEmits<{
  error: [boolean];
}>();

function addPhoneNumber() {
  patientCreateForm.value.phone_numbers.push({
    phone_number: "",
    phone_number_type: "MC",
    preferred: false,
  });
}

function addEmailAddress() {
  patientCreateForm.value.email_addresses.push({
    email_address: "",
    use_for_appointment: true,
    use_for_financial: true,
    use_for_medical: true,
  });
}

function removePhoneNumber(index: number) {
  patientCreateForm.value.phone_numbers.splice(index, 1);
}

function removeEmailAddress(index: number) {
  patientCreateForm.value.email_addresses.splice(index, 1);
}
</script>
