<template>
  <flux-card shadow="never">
    <flux-short-form>
      <div
        class="contents"
        v-for="(phone_number, index) in form.phone_numbers"
        :key="'phone' + index.toString()"
      >
        <flux-short-form-item
          :label="$t('patient.create.form.step.phone_number.phone_number')"
          span="col-span-10 md:col-span-6"
        >
          <flux-input
            v-model:modelValue="form.phone_numbers[index].phone_number"
            type="tel"
            :prop="
              'telephone_numbers.' + index.toString() + '.telephone_number'
            "
          />
        </flux-short-form-item>
        <flux-short-form-item span="col-span-2 md:hidden">
          <flux-button
            type="text"
            size="small"
            icon="fal fa-times"
            @click="removePhoneNumber(index)"
          />
        </flux-short-form-item>
        <flux-short-form-item
          :label="$t('patient.create.form.step.phone_number.phone_number_type')"
          span="col-span-6 md:col-span-5"
        >
          <flux-select
            v-model:modelValue="form.phone_numbers[index].phone_number_type"
          >
            <option
              v-for="(phoneNumberType, index2) in phoneNumberTypes"
              :key="'phonet' + index2.toString()"
              :value="phoneNumberType"
              :label="$t(`phone_number.type.${phoneNumberType}`).toString()"
            >
              {{ $t(`phone_number.type.${phoneNumberType}`) }}
            </option>
          </flux-select>
        </flux-short-form-item>
        <flux-short-form-item span="col-span-1 hidden md:block">
          <flux-button
            type="text"
            size="small"
            icon="fal fa-times"
            @click="removePhoneNumber(index)"
          />
        </flux-short-form-item>
      </div>
      <div class="h-4" v-if="form.phone_numbers.length === 0" />
      <div class="col-span-12 -mt-4">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-plus"
          @click="addPhoneNumber"
        >
          {{ $t("patient.create.form.step.phone_number.add_more") }}
        </flux-button>
      </div>
      <PatientEmailAddressFormVue
        v-for="(emailAddress, index3) in form.email_addresses"
        v-model:emailAddress="form.email_addresses[index3]"
        :key="'email' + index3.toString()"
        @error="(v) => handleEmailError(index3, v)"
        @remove="removeEmailAddress(index3)"
      />
      <div class="col-span-12 -mt-4">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-plus"
          @click="addEmailAddress"
        >
          {{ $t("patient.create.form.step.email_address.add_more") }}
        </flux-button>
      </div>
    </flux-short-form>
    <flux-button-group class="justify-end">
      <flux-button :disabled="!isDirty" @click="reset" type="secondary">{{
        $t("general.cancel")
      }}</flux-button>
      <flux-submit-button
        :disabled="!isDirty || hasInputError"
        @click="save"
        type="primary"
        >{{ $t("general.save") }}</flux-submit-button
      >
    </flux-button-group>
  </flux-card>
</template>

<script setup lang="ts">
import { apiClient } from "@/libraries/utils/axios";
import { ref, reactive, toRef, toRaw, watch, computed } from "vue";
import {
  PhoneNumberType,
  phoneNumberTypes,
  PhoneNumberPersisted,
} from "../../models/PhoneNumber";
import { useNotify } from "@/composables/notify";
import { $t } from "@/libraries/i18n";
import { usePatient } from "@/composables/patient";
import { clone } from "@/libraries/utils/clone";
import { PatientEmailAddress } from "@/models/EmailAddress";
import { PatientEmailAddressForm } from "@/models/Forms";
import PatientEmailAddressFormVue from "../PatientEmailAddressForm.vue";

const { notify } = useNotify();

interface PhoneNumberForm {
  id?: number;
  preferred: boolean;
  phone_number: string;
  phone_number_type: PhoneNumberType;
}

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient, invalidatePatient } = usePatient(
  toRef(props, "zisNumber"),
);

const loading = ref(false);
const emit = defineEmits<{
  (e: "updated"): void;
  (e: "loading", value: boolean): void;
}>();

const form: {
  phone_numbers: (PhoneNumberPersisted | PhoneNumberForm)[];
  email_addresses: (PatientEmailAddress | PatientEmailAddressForm)[];
} = reactive({
  phone_numbers: [],
  email_addresses: [],
});

const initialState = ref<{
  phone_numbers: (PhoneNumberPersisted | PhoneNumberForm)[];
  email_addresses: (PatientEmailAddress | PatientEmailAddressForm)[];
}>({ phone_numbers: [], email_addresses: [] });

watch(
  patient,
  () => {
    if (patient.value) {
      form.email_addresses = toRaw(patient.value.email_addresses);
      form.phone_numbers = toRaw(patient.value.phone_numbers);

      // Clone for tracking changes
      initialState.value = clone(form);
    }
  },
  { immediate: true },
);

const isDirty = ref(false);

watch(
  form,
  () => {
    isDirty.value = true;
  },
  { deep: true },
);

const inputErrors = ref<Map<number, boolean>>(new Map());
const hasInputError = computed(() =>
  Array.from(inputErrors.value.values()).some((v) => v === true),
);

function handleEmailError(index: number, hasError: boolean) {
  inputErrors.value.set(index, hasError);
}

function save() {
  if (!patient.value) {
    throw new Error("no patient");
  }
  loading.value = true;
  emit("loading", true);

  // Find new PN's
  const created_promise: Promise<any>[] = form.phone_numbers
    .filter((pn) => !pn.id)
    .map((pn) => apiClient.post("/phone_numbers", pn));

  // Find updated PN's
  const updated = form.phone_numbers
    .filter((pn) => pn.id)
    .filter(
      (pn) =>
        !initialState.value.phone_numbers.some(
          (pn2) => pn2.phone_number === pn.phone_number,
        ),
    );

  // Update PN's
  const updated_promise: Promise<any>[] = updated.map((pn) =>
    apiClient.put("/phone_numbers/:id", pn, {
      params: { id: pn.id as number },
    }),
  );

  // Find deleted phone numbers
  const deleted_phone_numbers = initialState.value.phone_numbers.filter(
    (pn) => !form.phone_numbers.some((pn2) => pn2.id === pn.id),
  );

  // Delete phone numbers
  const deleted_phone_number_promise: Promise<any>[] =
    deleted_phone_numbers.map((pn) =>
      apiClient.delete("/phone_numbers/:id", {
        params: { id: pn.id as number },
      }),
    );

  // Find new emails
  const created_email_promise: Promise<any>[] = form.email_addresses
    .filter((email) => !email.id)
    .map((email) => apiClient.post("/email_addresses", email));

  // Find updated emails
  const updated_email = form.email_addresses
    .filter((email) => email.id)
    .filter((email) => {
      const original = initialState.value.email_addresses.find(
        (email2) => email2.id === email.id,
      );
      if (!original) return true;

      // Check if any property has changed
      return (
        email.email_address !== original.email_address ||
        email.use_for_appointment !== original.use_for_appointment ||
        email.use_for_financial !== original.use_for_financial ||
        email.use_for_medical !== original.use_for_medical
      );
    });

  // Update emails
  const updated_email_promise: Promise<any>[] = updated_email.map((email) =>
    apiClient.put("/email_addresses/:id", email, {
      params: { id: email.id as number },
    }),
  );

  // Find deleted emails
  const deleted_emails = initialState.value.email_addresses.filter(
    (email) => !form.email_addresses.some((email2) => email2.id === email.id),
  );

  // Delete emails
  const deleted_email_promise: Promise<any>[] = deleted_emails.map((email) =>
    apiClient.delete("/email_addresses/:id", {
      params: { id: email.id as number },
    }),
  );

  const promises = [
    created_promise,
    updated_promise,
    created_email_promise,
    updated_email_promise,
    deleted_email_promise,
    deleted_phone_number_promise,
  ].flat();

  const P = Promise.allSettled(promises);
  P.then(() => {
    notify({
      message: $t("patient.edit.contactinfo.success") as string,
      type: "success",
    });
  }).finally(() => {
    emit("updated");
    loading.value = false;
    emit("loading", false);
    initialState.value = clone(form);
    invalidatePatient(props.zisNumber);
    isDirty.value = false;
  });
}

function addPhoneNumber() {
  form.phone_numbers.push({
    preferred: false,
    phone_number: "",
    phone_number_type: "HP" as PhoneNumberType,
    patient_zis_number: patient.value?.zis_number,
  } as PhoneNumberForm);
}

function addEmailAddress() {
  form.email_addresses.push({
    email_address: "",
    patient_zis_number: patient.value?.zis_number,
    use_for_appointment: true,
    use_for_financial: true,
    use_for_medical: true,
  } as PatientEmailAddressForm);
}

function removePhoneNumber(index: number) {
  form.phone_numbers.splice(index, 1);
}

function removeEmailAddress(index: number) {
  form.email_addresses.splice(index, 1);
}

function reset() {
  form.email_addresses = clone(initialState.value.email_addresses);
  form.phone_numbers = clone(initialState.value.phone_numbers);
  isDirty.value = false;
}
</script>
